import { css } from '@emotion/react';
import { useEffect, useMemo, useRef } from 'react';
import { Heading, ParagraphText } from 'components/typography';
import { Flex, Box, Grid } from 'components/box';
import { GalleryItem, Shop as ShopType } from 'types/types';
import Color from '../../util/color';
import Button from 'components/button';
import {
  ChevronLeft as IconPrevious,
  ChevronRight as IconNext,
} from 'react-feather';
import useSynchronisedCarousel from 'hooks/use-synchronised-carousel';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_WITH_CATEGORIES_DESKTOP,
} from 'components/_global/header';
import {
  isGiftVoucherGalleryItem,
  isImage,
  isProduct,
} from 'types/guards/gallery';
import GalleryImage from 'components/_shared/widgets/gallery/image';
import GiftVoucherGalleryItem from 'components/_shared/widgets/gallery/gift-voucher';
import GalleryProduct, {
  prepProductSizes,
} from 'components/_shared/widgets/gallery/product';
import { ItemSizeEnum } from 'components/_shared/widgets/gallery/shared';

export type ShopProps = ShopType & {
  items: GalleryItem[];
  totalItems: number;
};

const SHOP_ITEM_WIDTH = [150, 170, 210, 210];
const MIN_ITEMS_PER_ROW = [2, 4, 4, 5];
const CAROUSEL_MOVE_AMOUNT = 300;

const prepShopColumns = (totalItems: number) => {
  const numColumns = Math.ceil(totalItems / 2);
  const galleryColumns = SHOP_ITEM_WIDTH.map(
    (width, idx) =>
      `repeat(${Math.max(numColumns, MIN_ITEMS_PER_ROW[idx])}, ${width}px)`
  );
  return galleryColumns;
};

export const getRandomColor = () => {
  const colors = ['#0DBAB3', '#E5AF0E', '#3A3B46', '#05be99'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const ShopBorder = ({ color }: { color: string }) => (
  <Box
    height="4px"
    css={css`
      background: linear-gradient(
        to right,
        ${Color(color).lighten(0.1).string()},
        ${Color(color).string()}
      );
    `}
  />
);

const ShopProduct = ({
  item,
  isSkeleton,
}: {
  item: GalleryItem;
  isSkeleton?: boolean;
}) => {
  const itemSizes = [ItemSizeEnum.large];
  const productSizes = prepProductSizes(itemSizes, [1]);
  return (
    <Box key={isProduct(item) ? item.id : `${item.image.url}-${item.position}`}>
      {isGiftVoucherGalleryItem(item) && (
        <GiftVoucherGalleryItem
          image={item}
          widths={productSizes.imageWidths}
          sizes={productSizes.imageSizes}
          isSkeleton={isSkeleton}
        />
      )}
      {isImage(item) && (
        <GalleryImage
          image={item}
          widths={productSizes.imageWidths}
          sizes={productSizes.imageSizes}
          isSkeleton={isSkeleton}
        />
      )}
      {isProduct(item) && (
        <GalleryProduct
          isSkeleton={isSkeleton}
          product={item}
          sizes={productSizes}
        />
      )}
    </Box>
  );
};

const NavigationButton = ({
  variant,
  onClick,
  disabled,
}: {
  variant: 'left' | 'right';
  onClick: () => void;
  disabled: boolean;
}) => (
  <Button
    variant="solid"
    p={0}
    borderRadius="50%"
    color="transparent"
    shouldFocus={false}
    hoverColor="transparent"
    fontColor="primary"
    onClick={onClick}
    disabled={disabled}
  >
    {variant === 'right' ? <IconNext size="24" /> : <IconPrevious size="24" />}
  </Button>
);

const StickyShopHeader = ({
  carouselRef,
  color,
  id,
  name,
  totalItems,
  disableNavigation,
}: {
  carouselRef: React.RefObject<HTMLDivElement>;
  color: string;
  id: string;
  name: string;
  totalItems: number;
  disableNavigation: boolean;
}) => {
  const isCategoryBarVisible = useSelector(
    (state: RootState) => state.global.isCategoryBarVisible
  );

  useEffect(() => {
    const el = document.querySelector(`#sticky-header-${id}`);
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle('is-pinned', e.intersectionRatio < 1);
      },
      {
        threshold: [1],
        rootMargin: `-${
          isCategoryBarVisible
            ? HEADER_HEIGHT_WITH_CATEGORIES_DESKTOP + 1
            : HEADER_HEIGHT_DESKTOP + 1
        }px 0px 0px 0px`,
      }
    );

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [isCategoryBarVisible, id]);

  const {
    leftButtonDisabled,
    rightButtonDisabled,
    carouselNavigation,
  } = useSynchronisedCarousel(CAROUSEL_MOVE_AMOUNT, carouselRef);

  return (
    <Box
      id={`sticky-header-${id}`}
      position={['initial', null, 'sticky']}
      top={
        isCategoryBarVisible
          ? `${HEADER_HEIGHT_WITH_CATEGORIES_DESKTOP}px`
          : `${HEADER_HEIGHT_DESKTOP}px`
      }
      css={theme => css`
        @media ${theme.mediaQueries.desktopUp} {
          transition: all 0.3s ease-in-out;
          &.is-pinned {
            box-shadow: 0px 0px 5px #bbbbdd;
          }
        }
      `}
      backgroundColor="shopGrey"
      zIndex={100}
    >
      <ShopBorder color={color} />
      <Flex
        justifyContent="space-between"
        px={[3, null, null, 'calc((100vw - 1200px)/2)']}
        py={2}
      >
        <Flex alignItems="end">
          <Heading
            tabIndex={0}
            aria-label={`${name} Shop`}
            textAlign="left"
            fontWeight={700}
            color={color ?? 'darkestGrey'}
            fontSize={[3, 4]}
            css={css`
              user-select: none;
            `}
          >
            {name}
          </Heading>
          <ParagraphText color="darkishGrey" fontSize={[1, 2]} ml={2}>
            ({totalItems} {totalItems === 1 ? 'deal' : 'deals'})
          </ParagraphText>
        </Flex>
        <Flex
          className="show-for-desktop"
          css={css`
            gap: 20px;
          `}
        >
          <NavigationButton
            variant="left"
            disabled={leftButtonDisabled || disableNavigation}
            onClick={() => carouselNavigation('previous')}
          />
          <NavigationButton
            variant="right"
            disabled={rightButtonDisabled || disableNavigation}
            onClick={() => carouselNavigation('next')}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const Shop = ({
  totalItems,
  color,
  name,
  image,
  position,
  items,
  isSkeleton,
  ...rest
}: ShopProps & { isSkeleton: boolean }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const galleryColumns = useMemo(() => prepShopColumns(totalItems), [
    totalItems,
  ]);

  return (
    <Box
      {...rest}
      css={css`
        margin-left: calc(-1 * (100vw - 100%) / 2);
        margin-right: calc(-1 * (100vw - 100%) / 2);
      `}
      mb={[3, null, 4]}
    >
      <StickyShopHeader
        carouselRef={carouselRef}
        color={color || ''}
        id={rest.id || ''}
        name={name}
        totalItems={totalItems}
        // Target desktop specifically since navigation is hidden on mobile
        disableNavigation={totalItems <= MIN_ITEMS_PER_ROW[3] * 2}
      />
      <Flex
        ref={carouselRef}
        bg="shopGrey"
        pt={3}
        pb={2}
        overflowX="scroll"
        overflowY="hidden"
        gridColumnGap="32px"
        width="100vw"
        px={[3, null, null, 'calc((100vw - 1200px)/2)']}
        css={css`
          gap: 32px;
        `}
      >
        <Grid
          gridTemplateColumns={galleryColumns}
          gridColumnGap={4}
          gridRowGap={2}
        >
          {items.map((item, idx) => (
            <ShopProduct key={idx} item={item} isSkeleton={isSkeleton} />
          ))}
        </Grid>
      </Flex>
      <ShopBorder color={color || ''} />
    </Box>
  );
};

const Wrapper = ({
  isSkeleton,
  ...restProps
}: ShopProps & { isSkeleton: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <section ref={ref}>
      <Shop {...restProps} isSkeleton={isSkeleton} />
    </section>
  );
};

export default Wrapper;
